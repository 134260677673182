var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0 d-flex"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Assignments")) + " ")])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-input', {
    staticClass: "min-w-300px mr-0 mb-0",
    attrs: {
      "placeholder": _vm.$t('Search'),
      "debounce": 300
    },
    model: {
      value: _vm.searchFilter,
      callback: function callback($$v) {
        _vm.searchFilter = $$v;
      },
      expression: "searchFilter"
    }
  })], 1)]), _c('div', {
    staticClass: "text-capitalize card-body detail"
  }, [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "per-page-options": _vm.perPageOptions,
      "filter": _vm.searchFilter,
      "options": _vm.tableOptions,
      "total": _vm.total
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.country",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "font-weight-normal"
        }, [_c('country-flag', {
          attrs: {
            "country-iso": item.country
          }
        })], 1)];
      }
    }, {
      key: "cell.type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return _vm._l(item.type, function (i) {
          return _c('span', {
            key: i,
            staticClass: "label label-lg font-weight-bolder mr-1 label-inline",
            class: {
              'label-light-info': i == 'supplier',
              'label-light-warning': i == 'contractor',
              'label-light-primary ': i == 'client'
            }
          }, [_vm._v(_vm._s(i))]);
        });
      }
    }, {
      key: "cell.date_start",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(" " + _vm._s(item.date_start ? _vm.DateService.format(item.date_start, _vm.dateFormat) : "") + " ")])];
      }
    }, {
      key: "cell.date_end",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(item.date_end ? _vm.DateService.format(item.date_end, _vm.dateFormat) : ""))])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('View Details')
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }