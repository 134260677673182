<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0 d-flex">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Assignments") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="min-w-300px mr-0 mb-0" :debounce="300">
        </euro-input>
      </div>
    </div>
    <div class="text-capitalize card-body detail">
      <datatable ref="datatableRef" :table-props="tableProps" :per-page-options="perPageOptions" :filter="searchFilter"
        :options.sync="tableOptions" :total="total">
        <template #[`cell.country`]="{ item }">
          <div class="font-weight-normal">
            <country-flag :country-iso="item.country"></country-flag>
          </div>
        </template>
        <template #[`cell.type`]="{ item }">
          <span v-for="i in item.type" :key="i" class="label label-lg font-weight-bolder mr-1 label-inline" :class="{
            'label-light-info': i == 'supplier',
            'label-light-warning': i == 'contractor',
            'label-light-primary ': i == 'client',
          }">{{ i }}</span>
        </template>

        <template #[`cell.date_start`]="{ item }">
          <div class="font-weight-bolder text-muted">
            {{ item.date_start ? DateService.format(item.date_start, dateFormat) : "" }}
          </div>
        </template>

        <template #[`cell.date_end`]="{ item }">
          <div class="font-weight-bolder text-muted">{{ item.date_end ? DateService.format(item.date_end, dateFormat) : ""
          }}</div>
        </template>


        <template #[`cell.actions`]="{ item }">
          <div class="d-flex">
            <b-button v-b-tooltip.hover :title="$t('View Details')"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="edit(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
            </b-button>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CountryFlag from "@/view/components/CountryFlag.vue";
import Datatable from "@/view/components/tables/Datatable.vue";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";



export default {
  components: {
    Datatable,
    CountryFlag,
  },

  props: {
    physicalPerson: {
      type: Object,
      default: () => { },
    },
    assignments: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      DateService: DateService,
      fields: [
        { label: this.$t("Country"), key: "country", sortable: true },
        { label: this.$t("Type"), key: "type", class: ["font-weight-bolder"] },
        { label: this.$t("From Date"), key: "date_start", sortable: true, class: ["font-weight-bolder"] },
        { label: this.$t("To Date"), key: "date_end", sortable: true, class: ["font-weight-bolder"] },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: "",
      total: 0,
      secondments: [],
      fields_client: 'secondment_client_relations.secondment.id,secondment_client_relations.secondment.country,secondment_client_relations.secondment.date_start,secondment_client_relations.secondment.date_end',
      fields_partner: 'secondment_partner_relations.secondment_client.secondment.id,secondment_partner_relations.secondment_client.secondment.country,secondment_partner_relations.secondment_client.secondment.date_start,secondment_partner_relations.secondment_client.secondment.date_end',
      fields_contractor: 'secondment_contractor_relations.secondment_client.secondment.id,secondment_contractor_relations.secondment_client.secondment.country,secondment_contractor_relations.secondment_client.secondment.date_start,secondment_contractor_relations.secondment_client.secondment.date_end',
    };
  },
  computed: {
    ...mapGetters("user", ["dateFormat", "isApStaff"]),
    tableProps() {
      return {
        items: this.loadAssignments,
        fields: this.fields,
        filter: this.searchFilter,
      };
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "manage-physical-person" } },
      { title: this.$t("Assignments") },
    ]);
  },

  methods: {
    edit(item) {
      this.$router.push({
        name: "detail-secondment",
        params: { secondmentId: item.id },
      });
    },

    refresh() {
      this.$refs.datatableRef.refresh();
    },

    getSecondmentList(secondment) {
      this.secondments = secondment.secondment_client_relations.map(el => ({ ...el.secondment, type: ['client'] }));
      secondment.secondment_partner_relations.forEach(el => {
        let secondment_data = el.secondment_client.secondment;
        let secondment = this.secondments.find(s => s.id == secondment_data.id)
        if (secondment) {
          secondment.type.push('supplier');
        } else {
          secondment_data.type = ['supplier'];
          this.secondments.push(secondment_data);
        }
      });
      secondment.secondment_contractor_relations.forEach(el => {
        let secondment_data = el.secondment_client.secondment;
        let secondment = this.secondments.find(s => s.id == secondment_data.id);
        if (secondment) {
          secondment.type.push('contractor');
        } else {
          secondment_data.type = ['contractor'];
          this.secondments.push(secondment_data);
        }
      });
    },

    loadAssignments(ctx, callback) {
      let partner_contractor = this.fields_partner.concat(",", this.fields_contractor)
      let result = this.fields_client.concat(",", partner_contractor)
      PhysicalPersonService.getOne(this.physicalPerson.id, this.isApStaff ? true : null, result, 'secondment_client_relations.secondment,secondment_partner_relations.secondment_client.secondment,secondment_contractor_relations.secondment_client.secondment',
      ).then(res => {
        this.getSecondmentList(res.data);
        this.total = this.secondments.length;
        callback(this.secondments);
      }).catch(err => {
        console.log(err);
        callback([]);
        // Nel caso in cui non sia autorizzato l'errore è comunque 404
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    },

    calculateDays(start, end) {
      let days = DateService.diff(end, start);
      return days + 1;
    }
  },
};
</script>

<style scoped lang="scss">
.employees-dropdown {
  font-size: 12px;
  text-transform: capitalize;
  text-decoration: underline;
  color: var(--primary);
}

.text-capitalize {
  text-transform: capitalize;
}

::v-deep .fixed-width {
  max-width: 13ch;
  flex-grow: 0;
}
</style>
